@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: var(--font-family-proxima);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-family-proxima: 'Proxima Nova';
  --primary-500: #202b50;
  --primary-300: #9497af;
  --grey-100: #f4f4f4;
  --grey-200: #f9f9ff;
  --box-shadow-medium: 0px 10px 20px -12px rgba(16, 24, 40, 1.25);
}
